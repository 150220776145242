const state = {
	activeProduct: {}
}

const getters = {
	getActiveProduct: (state) => {
		return state.activeProduct
	}
}

const actions = {}

const mutations = {
	setActiveProduct(state, product) {
		state.activeProduct = product
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
