const state = {
	json: {}
}

const getters = {
	getProperty:
		(state) =>
		(property, default_value = null) => {
			const result = property.split('.').reduce((o, x) => {
				return typeof o == 'undefined' || o === null ? o : o[x]
			}, state.json)
			return typeof result == 'undefined' ? default_value : result
		},
	getConfigJsonString: (state) => {
		return JSON.stringify(state.json)
	}
}

const actions = {}

const mutations = {
	store(state, json) {
		state.json = json
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
