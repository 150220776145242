<template>
	<transition name="fade">
		<div v-if="isShowing" class="notification" :class="[options.type]">
			<div class="notification--title">{{ title }}</div>
			<div class="notification--text">{{ text }}</div>
		</div>
	</transition>
</template>

<script setup>
import { EventBus } from '@/utils/eventBus.js'
import { computed, onMounted, ref } from '@vue/runtime-core'
import { useStore } from 'vuex'

const bindEvents = () => EventBus.on('notify', showNotification)
onMounted(bindEvents)

const store = useStore()
const options = ref({})
const title = ref('')
const text = ref('')
const isShowing = ref(false)
const offsetTop = computed(() => store.state.offset_top + 'px')

function showNotification({ content, params = {} }) {
	options.value = {
		type: 'error',
		timeout: 3000,
		...params
	}

	title.value = content.title
	text.value = content.text
	isShowing.value = true
	setTimeout(clearNotification, options.value.timeout)
}

function clearNotification() {
	isShowing.value = false
	title.value = ''
	text.value = ''
}
</script>

<style lang="scss" scoped>
.notification {
	padding: 1rem 1.1rem;
	border-radius: 8px;
	position: fixed;
	top: calc(v-bind(offsetTop) + 1rem);
	left: 50%;
	transform: translateX(-50%);
	width: calc(100% - 20px);
	z-index: 99;

	&.error {
		background: #fbded8;
		border: 1px solid #f0907e;
	}

	&.success {
		background: lighten(rgb(0, 185, 99), 15%);
		border: 1px solid lighten(rgb(0, 185, 99), 20%);
	}

	&--title {
		color: #323653;
		font-weight: 700;
		font-size: 1.1rem;
	}

	&--text {
		margin-top: 0.3rem;
		font-size: 0.92rem;
		line-height: 1.3;
	}
}
</style>
