const state = {
	improving_life: [],
	state_of_mind: [],
	diets: [],
	goals: [],
	ads_source: [],
	rejection_reason: [],
	cansel_sub_reason: [],
	grab_snack: [],
	snack_trigger: []
}

const getters = {
	getProperty: (state) => (data) => {
		return data.options.map((option) => {
			option.selected = state[data.key].includes(option.label)
			return option
		})
	},
	isOnboarded(state) {
		return [state.improving_life, state.state_of_mind, state.diets, state.goals].every((el) => {
			return el.length
		})
	}
}

const actions = {}

const mutations = {
	update(state, data) {
		state[data.property] = data.value
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
