import useEnv from '@/composition/env.js'

export default {
	install: (app) => {
		const env = useEnv()

		const request = async (endpoint, body) => {
			return await fetch(`https://rest.iad-05.braze.com/${endpoint}`, {
				headers: {
					Authorization: `Bearer ${env('BRAZE_API_KEY')}`,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(body),
				method: 'POST'
			})
		}

		const $appboy = {
			// https://www.braze.com/docs/api/endpoints/user_data/post_user_track
			async userTrack(payload) {
				return await request('users/track', payload)
			},

			async canvas(canvas_id, user_id, canvas_entry_properties = {}) {
				return await fetch('https://rest.iad-05.braze.com/canvas/trigger/send', {
					body: JSON.stringify({
						canvas_id,
						recipients: [
							{
								external_user_id: user_id,
								canvas_entry_properties
							}
						]
					}),
					headers: {
						Authorization: `Bearer ${env('BRAZE_CANVAS_API_TOKEN')}`,
						'Content-Type': 'application/json'
					},
					method: 'POST'
				})
			},

			async campaign(campaign_id, user_id, canvas_entry_properties = {}) {
				return await fetch('https://rest.iad-05.braze.com/campaigns/trigger/send', {
					body: JSON.stringify({
						campaign_id,
						recipients: [
							{
								external_user_id: user_id,
								canvas_entry_properties
							}
						]
					}),
					headers: {
						Authorization: `Bearer ${env('BRAZE_CAMPAIGN_API_TOKEN')}`,
						'Content-Type': 'application/json'
					},
					method: 'POST'
				})
			}
		}

		app.provide('appboy', $appboy)
	}
}
