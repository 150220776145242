const state = {
	env: null,
	development: {
		stripeApiKey: 'pk_test_yEFUsiwhzwEHQDozzGNw6j6a00UXWSg0nY',
		billingApiHost: 'https://api.simple.life/sandbox/',
		stripeProductId: 'prod_MW3DXmqCOFUvnq',
		braintreeClientApiKey: 'sandbox_7b27wcqm_c26s349gchcx25ct'
	},
	production: {
		stripeApiKey: 'pk_live_rPibmqvx4VnIYpYIoBuiy4oL00PGZktmD3',
		billingApiHost: 'https://api.simple.life/',
		stripeProductId: 'prod_MNn6Hzs8AM1kwC',
		braintreeClientApiKey: 'production_gptpb7ch_433xxb89rdv8fqqg'
	}
}

const getters = {
	getEnv: (state) => state.env ?? 'development',

	getProperty: (state, getters) => (property) => {
		return state[getters.getEnv][property]
	}
}

const mutations = {
	setEnv(state, env) {
		state.env = env
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}
