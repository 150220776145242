import useConfig from '@/composition/config'
import { computed } from 'vue'

export function useTheme() {
	const config = useConfig()
	const theme = computed(() => config.get({ key: 'theme', default_value: false }))

	return {
		isTheme: (value) => theme.value === value,
		theme
	}
}
