import { convertWeights } from '@/utils/helpers'
const state = {
	user_id: null,
	email: null,
	data: null
}

const getters = {
	hasEmail(state) {
		return Boolean(state.email)
	},
	targetWeightByUnitSystem(state, getters) {
		return `${convertWeights(state.data?.targetWeight, state.data?.usesMetricSystem)} ${getters['metricSystem']}`
	},
	metricSystem: (state) => {
		return state.data?.usesMetricSystem ? 'kg' : 'lbs'
	},
	predictiveWeightLossWeeksToGoal(state) {
		return state.data?.data?.predictiveWeightLossDaysToGoal
			? Math.round(state.data?.data?.predictiveWeightLossDaysToGoal / 7)
			: 12
	}
}

const actions = {
	initializeBrazeUser({ commit }, user_id) {
		commit('setUserId', user_id)
	}
}

const mutations = {
	setUserId(state, user_id) {
		state.user_id = user_id
	},

	setEmail(state, email) {
		state.email = email
	},

	setUserData(state, userData) {
		const { data } = userData
		if (data) userData.data = JSON.parse(data)
		state.data = userData
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
