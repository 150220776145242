const state = {
	subscription: null
}

const getters = {
	hasSubscription: (state) => Boolean(state.subscription),

	get: (state) => {
		return state.subscription ?? {}
	}
}

const mutations = {
	store(state, subscription) {
		state.subscription = subscription
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}
