<template>
	<transition name="fade">
		<template v-if="show">
			<div v-if="loaderTheme == 'blured'" class="loader blured">
				<Spinner class="loader__spinner" />

				<div v-if="loaderContent" class="loader__content">
					<p v-for="item in loaderContent" :key="item">{{ item }}</p>
				</div>
			</div>
			<div v-else class="loader default">
				<Spinner />
			</div>
		</template>
	</transition>
</template>

<script setup>
import { computed } from '@vue/runtime-core'
import useConfig from '@/composition/config.js'
import Spinner from '@/components/Spinner'

const props = defineProps({
	show: {
		type: Boolean,
		default: false
	},
	theme: {
		type: [String, null],
		default: null,
		validator: (value) => {
			return ['blured'].includes(value)
		}
	}
})

const config = useConfig()
const loaderTheme = computed(() => config.get({ key: 'full_screen_loader.theme', default_value: props.theme }))
const loaderContent = computed(() => config.get({ key: 'full_screen_loader.content', default_value: null }))
</script>

<style lang="scss" scoped>
.loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: var(--font-family, 'Roboto'), sans-serif;

	&.default {
		background-color: rgba(0, 0, 0, 0.6);
	}

	&.blured {
		background: rgba(0, 0, 0, 0.6);
		backdrop-filter: blur(25px);
	}

	&__spinner {
		color: #fff;
	}

	&__content {
		text-align: center;
		color: #fff;
		font-weight: bold;
		font-size: 1.25rem;
		margin-top: 1rem;
	}
}

body.loading {
	overflow: hidden;

	.loader {
		opacity: 1;
		visibility: visible;
	}
}
</style>
