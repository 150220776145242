import { createStore, createLogger } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import user from './modules/user'
import products from './modules/products'
import config from './modules/config'
import survey from './modules/survey'
import jf from './modules/jf'
import env from './modules/env'
import subscription from './modules/subscription'

const debug = process.env.NODE_ENV !== 'production'
const SOURCE_ONBOARDING = 'Onboarding'
const SOURCE_IN_APP = 'InApp'

const state = {
	isLoading: false,
	offset_top: 0,
	offset_bottom: 0,
	source: null,
	lastPaymentMethod: null,
	purchasedSubscription: false
}

const getters = {
	getSource(state) {
		return state.source || SOURCE_ONBOARDING
	},
	getAmplitudeEventName: (state, getters) => (page) => {
		let prefix = getters.getSource === SOURCE_ONBOARDING ? SOURCE_ONBOARDING : SOURCE_IN_APP
		return prefix + page
	}
}

const actions = {}

const mutations = {
	setLoading(state, isLoading) {
		state.isLoading = isLoading

		if (isLoading) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'auto'
		}
	},

	setOffsets(state, offsets) {
		state.offset_top = offsets.top
		state.offset_bottom = offsets.bottom
	},

	setSource(state, source) {
		state.source = source
	},

	setLastPaymentMethod(state, pm) {
		state.lastPaymentMethod = pm
	},

	setPurchaseSubscription(state, val) {
		state.purchasedSubscription = val
	}
}

const dataState = createPersistedState({
	paths: ['survey', 'subscription', 'lastPaymentMethod', 'jf']
})

export default createStore({
	modules: {
		user,
		products,
		config,
		survey,
		env,
		subscription,
		jf
	},
	state,
	getters,
	actions,
	mutations,
	strict: debug,
	plugins: debug ? [createLogger(), dataState] : [dataState]
})

//  NEW SOLUTION coz vuex-persistedstate is just a localStorage and it's outdated. Need to test it better

// import { createStore, createLogger } from 'vuex'
// import user from './modules/user'
// import products from './modules/products'
// import config from './modules/config'
// import survey from './modules/survey'
// import env from './modules/env'
// import subscription from './modules/subscription'

// const debug = process.env.NODE_ENV !== 'production'
// const SOURCE_ONBOARDING = 'Onboarding'
// const SOURCE_IN_APP = 'InApp'

// const state = {
// 	isLoading: false,
// 	offset_top: 0,
// 	offset_bottom: 0,
// 	source: null,
// 	lastPaymentMethod: null,
// 	purchasedSubscription: false
// }

// const getters = {
// 	getSource(state) {
// 		return state.source || SOURCE_ONBOARDING
// 	},
// 	getAmplitudeEventName: (state, getters) => (page) => {
// 		let prefix = getters.getSource === SOURCE_ONBOARDING ? SOURCE_ONBOARDING : SOURCE_IN_APP
// 		return prefix + page
// 	}
// }

// const actions = {}

// const mutations = {
// 	setLoading(state, isLoading) {
// 		state.isLoading = isLoading

// 		if (isLoading) {
// 			document.body.style.overflow = 'hidden'
// 		} else {
// 			document.body.style.overflow = 'auto'
// 		}
// 	},

// 	setOffsets(state, offsets) {
// 		state.offset_top = offsets.top
// 		state.offset_bottom = offsets.bottom
// 	},

// 	setSource(state, source) {
// 		state.source = source
// 	},

// 	setLastPaymentMethod(state, pm) {
// 		state.lastPaymentMethod = pm
// 	},

// 	setPurchaseSubscription(state, val) {
// 		state.purchasedSubscription = val
// 	}
// }

// // Custom plugin to persist state to localStorage
// function createLocalStoragePlugin(options) {
// 	const storage = options.storage || window.localStorage
// 	const key = options.key || 'vuex'

// 	function getState(key, storage) {
// 		const item = storage.getItem(key)
// 		try {
// 			return item ? JSON.parse(item) : undefined
// 		} catch (error) {
// 			console.error(`Error parsing JSON from localStorage for key "${key}":`, error)
// 			return undefined
// 		}
// 	}

// 	function setState(key, state, storage) {
// 		try {
// 			const item = JSON.stringify(state)
// 			storage.setItem(key, item)
// 		} catch (error) {
// 			console.error(`Error setting JSON to localStorage for key "${key}":`, error)
// 		}
// 	}

// 	function reducer(state, paths) {
// 		return paths.reduce((subState, path) => {
// 			const keys = path.split('.')
// 			let currentState = state
// 			keys.forEach((key, index) => {
// 				if (index === keys.length - 1) {
// 					subState[key] = currentState[key]
// 				} else {
// 					currentState = currentState[key] || {}
// 					subState[key] = subState[key] || {}
// 					subState = subState[key]
// 				}
// 			})
// 			return subState
// 		}, {})
// 	}

// 	return (store) => {
// 		// Fetch initial state from localStorage
// 		const savedState = getState(key, storage)
// 		if (savedState) {
// 			store.replaceState({
// 				...store.state,
// 				...savedState
// 			})
// 		}

// 		// Subscribe to store mutations and persist state
// 		store.subscribe((mutation, state) => {
// 			const stateToSave = reducer(state, options.paths || [])
// 			setState(key, stateToSave, storage)
// 		})
// 	}
// }

// const localStoragePlugin = createLocalStoragePlugin({
// 	paths: ['survey', 'subscription', 'lastPaymentMethod'],
// 	key: 'vuex'
// })

// export default createStore({
// 	modules: {
// 		user,
// 		products,
// 		config,
// 		survey,
// 		env,
// 		subscription
// 	},
// 	state,
// 	getters,
// 	actions,
// 	mutations,
// 	strict: debug,
// 	plugins: debug ? [createLogger(), localStoragePlugin] : [localStoragePlugin]
// })
