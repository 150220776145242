import useApi from '@/composition/api.js'

export default {
	install: (app, { store }) => {
		const api = useApi()

		const $amplitude = {
			logEvent(event, properties = {}) {
				try {
					api.amplitude().logEvent(event, {
						...properties,
						is_fallback: false,
						type: 'web',
						source: store.getters['getSource'],
						platform: api.getPlatform(),
						onboarding_version: process.env.VUE_APP_VERSION,
						/**
						 * TODO: Legacy props
						 */
						from: store.getters['getSource']
					})
				} catch (e) {
					console.log(e)
				}
			}
		}

		app.provide('amplitude', $amplitude)
	}
}
