import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'

export default function useConfig() {
	const store = useStore()

	const get = (params) => {
		const { key, default_value } = params
		return computed(() => store.getters['config/getProperty'](key, default_value)).value
	}

	return {
		get
	}
}
