export default function useApi() {
	const init = () => {
		const sendDidLoadAction = () => {
			if (typeof window.fs_sendToApp === 'function') {
				window.fs_sendToApp({ action: 'didLoad' })
				return true
			}
			return false
		}

		return new Promise((resolve, reject) => {
			if (sendDidLoadAction()) {
				return resolve('Action sent immediately.')
			}

			let attempts = 10
			const interval = setInterval(() => {
				if (sendDidLoadAction()) {
					clearInterval(interval)
					resolve('Action sent after retry.')
				} else {
					attempts--
					if (attempts <= 0) {
						clearInterval(interval)
						reject(`Failed to call fs_sendToApp after ${attempts} attempts.`)
					}
				}
			}, 100)
		})
	}

	const close = () => {
		window.fs_sendToApp({ action: 'close' })
	}

	const terms = () => {
		window.fs_sendToApp({ action: 'terms' })
	}

	const privacy = () => {
		window.fs_sendToApp({ action: 'privacy' })
	}

	const restore = (params) => {
		const { closeOnSuccess, onFinish, onFail } = params

		window.fs_restoreDidFinish = onFinish
		window.fs_restoreDidFail = onFail

		window.fs_sendToApp({ action: 'restore', mode: 'subscription', closeOnSuccess })
	}

	const openExternalURL = (url, closeAfter) => {
		if (closeAfter) {
			window.fs_sendToApp({ action: 'openExternalUrlAndClose', url })
			return
		}
		window.fs_sendToApp({ action: 'openExternalURL', url })
	}

	const onBackBressed = () => {
		// window.history.back = () => {}
		// window.history.go = () => {}
		// window.goBack = () => {}
		window.canGoBack = () => {
			return true
		}
	}

	const purchase = (params) => {
		const { productId, closeOnSuccess, onFinish, onError, onCancel } = params

		window.fs_purchaseDidFinish = onFinish
		window.fs_purchaseDidFail = onError
		window.fs_purchaseDidCancel = onCancel

		window.fs_sendToApp({ action: 'purchase', productId, closeOnSuccess })
	}

	const rateUs = () => {
		window.fs_sendToApp({ action: 'rateUs', rateType: 'system' })
	}

	/**
	 * @param {Object} subscription
	 * @param {Object} product
	 * @returns {Promise}
	 */
	const confirmSubscription = (subscription, product) => {
		return new Promise((resolve) => {
			window.fs_confirmSubscription = (productId) => {
				resolve(subscription, productId)
			}
			window.fs_sendToApp?.({
				action: 'productPurchased',
				params: {
					id: subscription.id,
					trialPeriod: `P${product.trial_days}D`,
					subscriptionPeriod: `P${product.duration_months}M`,
					isSubscription: true,
					storeType: 'web',
					price: subscription.price,
					currency: subscription.currency
				}
			})

			if (process.env.NODE_ENV === 'development') {
				window.fs_confirmSubscription('fakeId')
			}
		})
	}

	/**
	 *
	 * @returns {Object}
	 */
	const amplitude = () => {
		const send = (data) => {
			const timeout = typeof window.fs_sendToApp == 'function' ? 0 : 500
			setTimeout(() => {
				window.fs_sendToApp({
					action: 'analyticsTrack',
					...data
				})
			}, timeout)
		}

		return {
			/**
			 *
			 * @param {String} name
			 * @param {Object} params
			 */
			logEvent(name, params) {
				send({ name, params })
			},

			/**
			 *
			 * @param {Object} options
			 */
			setUserProperties(options) {
				const { userProperties } = options
				send({ userProperties })
			}
		}
	}

	/**
	 *
	 * @returns {string} Return ios || android
	 */
	const getPlatform = () => {
		return window.fs_platform || 'ios'
	}

	const getLanguage = () => {
		return window.fs_language || 'en'
	}

	//
	return {
		init,
		close,
		terms,
		privacy,
		restore,
		purchase,
		rateUs,
		confirmSubscription,
		openExternalURL,
		onBackBressed,
		amplitude,
		getPlatform,
		getLanguage
	}
}
