import { differenceInCalendarDays } from 'date-fns'

const LBS_TO_KG = 2.2046226218

export const convertWeights = (value, isMetricSystem = true) => {
	if (!value) return null

	if (!isMetricSystem) {
		return Math.floor(value * LBS_TO_KG)
	}
	return Math.floor(value)
}

/**
 * @param {number} weight - User weight in kg
 * @param {number} height - User height in cm
 * @param {number} digits - Rounding result
 * @return {number}
 */
export function bmiCalculator(weight, height, digits = 1) {
	if (isNaN(weight) || isNaN(height)) {
		return 0
	}
	const heightInMeters = height / 100
	const bmi = weight / (heightInMeters * heightInMeters)

	return Math.round(bmi, digits)
}

// Implementation in iOS: https://gitlab.fstr.app/mobile/ios/-/blob/master/App/Simple/Source/Essentials/WeightLossCalculator.swift#L236
export function defineWeightLossSpeedDateHard(bmi, weight, targetWeight) {
	if (weight < targetWeight) return 0

	let daysToGoal = 7
	let kgPerWeek
	if (bmi < 25) kgPerWeek = 1
	if (bmi >= 25 && bmi < 30) kgPerWeek = 1.3
	if (bmi >= 30 && bmi < 40) kgPerWeek = 1.5
	if (bmi >= 40 && bmi < 50) kgPerWeek = 1.8
	if (bmi >= 50) kgPerWeek = 2.3

	while (weight >= targetWeight) {
		weight = weight - kgPerWeek
		daysToGoal = daysToGoal + 7
	}

	return daysToGoal
}

export function defineWeightLossTargetWeightBySpecialEventDate(
	weight,
	targetWeight,
	specialEventDate,
	daysToWeightLossDate = 1
) {
	let ratio = 1
	if (specialEventDate) {
		const daysToSpecialEventDate = differenceInCalendarDays(new Date(specialEventDate), new Date())
		if (daysToSpecialEventDate < daysToWeightLossDate) {
			ratio = daysToSpecialEventDate / daysToWeightLossDate
		} else {
			ratio = 1
		}
	}

	return Math.round((weight - targetWeight) * ratio)
}
