<template>
	<div class="spinner">
		<div :class="[spinnerTheme]"></div>
	</div>
</template>

<script setup>
import useConfig from '@/composition/config.js'
import { computed } from '@vue/runtime-core'

const props = defineProps({
	theme: {
		type: String,
		validator: (value) => {
			return ['spinner-bordered-circle', 'spinner-circle'].includes(value)
		},
		default: 'spinner-circle'
	},
	size: {
		type: String,
		default: '2rem'
	}
})

const config = useConfig()
const spinnerTheme = computed(() => config.get({ key: 'spinner.theme', default_value: props.theme }))
</script>

<style lang="scss" scoped>
.spinner {
	&-circle {
		display: block;
		width: v-bind('props.size');
		height: v-bind('props.size');
		border: 0.2em solid currentColor;
		border-right-color: transparent;
		border-radius: 50%;
		animation: 0.75s linear infinite spinner-border;
	}

	&-bordered-circle {
		font-size: 10px;
		position: relative;
		text-indent: -9999em;
		border-top: 0.4em solid rgba(255, 255, 255, 0.2);
		border-right: 0.4em solid rgba(255, 255, 255, 0.2);
		border-bottom: 0.4em solid rgba(255, 255, 255, 0.2);
		border-left: 0.4em solid #ffffff;
		transform: translateZ(0);
		animation: spinner-border 1.1s infinite linear;
		border-radius: 50%;
		width: v-bind('props.size');
		height: v-bind('props.size');

		&:after {
			border-radius: 50%;
		}
	}
}
</style>
