import { createI18n } from 'vue-i18n'
import useApi from '@/composition/api'
import messages from './messages.json'

const api = useApi()
const i18n = createI18n({
	legacy: false,
	locale: api.getLanguage(),
	fallbackLocale: 'en',
	messages
})

const setI18nLanguage = (lang) => {
	i18n.global.locale.value = lang
	document.querySelector('html').setAttribute('lang', lang)
}

document.querySelector('html').setAttribute('lang', i18n.global.locale.value)

export { i18n, setI18nLanguage }
