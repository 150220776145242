import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueScrollTo from 'vue-scrollto'
import Braze from '@/plugins/braze.js'
import Amplitude from '@/plugins/amplitude.js'
import { i18n } from './i18n'
import { isNavigationFailure, NavigationFailureType } from 'vue-router'

const app = createApp(App).use(router).use(store).use(Braze).use(Amplitude, { store }).use(i18n)

app.directive('scroll-to', VueScrollTo, { duration: 1000 })

app.config.errorHandler = (err) => {
	if (
		isNavigationFailure(err) ||
		isNavigationFailure(err, NavigationFailureType.duplicated) ||
		isNavigationFailure(err, NavigationFailureType.aborted | NavigationFailureType.canceled)
	) {
		if (!!window?.fs_sendToApp) {
			window.fs_sendToApp({ action: 'close' })
		}
	}
}

app.mount('#app')
